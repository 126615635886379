import { Icon, LoaderSpin } from '@/components/common';
import { IconType } from '@/consts';
import { Colors } from '@/environment';
import getUserIconColor from '@/helpers/getUserIconColor';
import { IMainUser } from '@/pages/ManagePublishers';
import { useLazyGetPubUsersQuery } from '@/redux/api/usersApi';
import { Nullable } from '@/types/generic';
import { IUser } from '@/types/users-types';
import { sortBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import {
  NameStyled,
  NestedUserListCardStyled,
  NestedUserListStyled,
  UserListCardStyled,
} from '../../Layouts/ManageLayout/ManageLayout.styled';
import { SelectLogoPlaceholder } from './PubSelectItemStyled';

export const PubSelectItem = ({
  id,
  name,
  setSelectedUser,
  getMainUser,
  setMainUser,
  mainUser,
  pubActive,
  selectedUser,
}: {
  id: string;
  name: string;
  setSelectedUser: (value: Nullable<IUser>) => void;
  getMainUser: (id: string, user: IUser) => void;
  setMainUser: (value: Nullable<IMainUser>) => void;
  mainUser: Nullable<IMainUser>;
  pubActive: boolean;
  selectedUser: Nullable<IUser>;
}) => {
  const [active, setActive] = useState<boolean>(false);
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const [users, setUsers] = useState<IUser[]>([]);

  const [getUsers, { isLoading }] = useLazyGetPubUsersQuery();

  function handleUserClick(user: IUser) {
    return () => {
      setMainUser(null);
      setSelectedUser(user);
    };
  }

  function handleSetShowIcon(show: boolean) {
    return () => {
      setShowIcon(show);
    };
  }

  function handleCardClick() {
    setSelectedUser(null);
    setActive((prev) => !prev);
  }

  const prepareUsers = useCallback((users: IUser[]) => {
    return sortBy(
      users.filter((user) => user.active),
      ['role'],
    );
  }, []);

  useEffect(() => {
    if (mainUser) {
      mainUser.id === id && setActive(true);
    }
  }, [id, mainUser]);

  useEffect(() => {
    if (active && users.length === 0) {
      getUsers({ publisherId: id })
        .unwrap()
        .then((res) => {
          setUsers(res);
          getMainUser(id, res.filter((user) => user.role === 'admin')[0]);
        });
    }

    if (users.length > 0 && active) {
      getMainUser(id, users.filter((user) => user.role === 'admin')[0]);
    }
  }, [active, getMainUser, getUsers, id, setMainUser, users, users.length]);

  return (
    <>
      <UserListCardStyled
        onClick={handleCardClick}
        onMouseEnter={handleSetShowIcon(true)}
        onMouseLeave={handleSetShowIcon(false)}
        selected={id === mainUser?.id && !selectedUser}
        className="active"
      >
        <SelectLogoPlaceholder pubActive={pubActive}>P</SelectLogoPlaceholder>
        <NameStyled
          active={pubActive}
          selected={id === mainUser?.id}
        >{`${name}`}</NameStyled>
        {isLoading && <LoaderSpin size={10} color={Colors.text.main} />}
        {!isLoading && (
          <>
            {active && <Icon name={IconType.TYPublisherArrowUp} color="red" />}
            {!active && showIcon && (
              <Icon name={IconType.TYPublisherArrowDown} color="red" />
            )}
          </>
        )}
      </UserListCardStyled>
      {active && users.length > 0 && (
        <NestedUserListStyled>
          {prepareUsers(users).map((user) => (
            <NestedUserListCardStyled
              key={user.id}
              onClick={handleUserClick(user)}
              selected={user.id === selectedUser?.id}
              className="active"
            >
              <Icon
                name={IconType.TYUserListIcon}
                color={
                  pubActive ? getUserIconColor(user.role) : Colors.grey[100]
                }
              />
              <NameStyled
                selected={user.id === selectedUser?.id}
                active={pubActive}
              >{`${user.firstName} ${user.lastName}`}</NameStyled>
            </NestedUserListCardStyled>
          ))}
        </NestedUserListStyled>
      )}
    </>
  );
};
