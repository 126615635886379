import { LoaderSpin } from '@/components/common';
import { PrivateRoute } from '@/components/common/PrivateRoute';
import { LoadPage } from '@/components/ui/LoadPage';
import { EUserRole } from '@/consts';
import { Colors } from '@/environment';
import { checkLengthIsPositive } from '@/helpers/checkLengthIsPositive';
import { useViewport } from '@/hooks/useViewport';
import { RootState } from '@/redux';
import { EAuthRoutes, EGeneralRoutes, ERoutes } from '@/routes';
import { lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Layout } from './layout';

const Login = lazy(() => import('@/pages/Login'));
const UserSignUp = lazy(() => import('@/pages/UserSignUp'));
const PublisherSignUp = lazy(() => import('@/pages/PublisherSignUp'));
const Confirm = lazy(() => import('@/pages/Confirm'));
const Forgot = lazy(() => import('@/pages/ForgotPassword'));
const Recover = lazy(() => import('@/pages/RecoverPassword'));
const ManageUsers = lazy(() => import('@/pages/ManageUsers'));
const ManagePublishers = lazy(() => import('@/pages/ManagePublishers'));
const PublisherDashboard = lazy(() => import('@/pages/PublisherDashboard'));
const OwnerDashboard = lazy(() => import('@/pages/OwnerDashboard'));
const ManagePlayers = lazy(() => import('@/pages/ManagePlayers'));
const InviteUser = lazy(() => import('@/pages/InviteUser'));
const InvitePublisher = lazy(() => import('@/pages/InvitePublisher'));
const CreateOrEditPlayer = lazy(() => import('@/pages/CreateOrEditPlayer'));
const PlayerDetails = lazy(() => import('@/pages/PlayerDetails'));
const NotFoundPage = lazy(() => import('@/pages/NotFoundPage'));
const NoMobile = lazy(() => import('@/pages/NoMobile'));
const Tutorials = lazy(() => import('@/pages/Tutorials'));

export const Router = () => {
  const { isAuthorized } = useSelector((store: RootState) => ({
    isAuthorized: store.user.isAuthorized,
  }));
  const { width } = useViewport();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const token = localStorage.getItem('accessToken');

  const { user, viewAsPublisherId, canViewMobile } = useSelector(
    (store: RootState) => ({
      user: store.user.data,
      viewAsPublisherId: store.user.viewAsPublisherId,
      canViewMobile: store.user.canViewMobile,
    }),
  );
  useEffect(() => {
    if (width < 1024 && !canViewMobile) {
      navigate(EGeneralRoutes.NoMobile, { replace: true });
    }
  }, [canViewMobile, navigate, width]);

  useEffect(() => {
    if (
      (isAuthorized || token) &&
      Object.values(EAuthRoutes).some((route) => '/' + route === pathname)
    ) {
      navigate(ERoutes.Root, { replace: true });
    }
  }, [isAuthorized, navigate, pathname, token]);

  return (
    <Routes>
      <Route path={EGeneralRoutes.NoMobile} element={<NoMobile />} />
      <Route path="login" element={<Navigate to={`/${EAuthRoutes.Login}`} />} />
      <Route
        path={EAuthRoutes.Login}
        element={
          <Suspense
            fallback={
              <LoaderSpin
                position="absolute"
                size={50}
                color={Colors.blue}
                thickness={0.4}
              />
            }
          >
            <Login />
          </Suspense>
        }
      />
      <Route
        path={EAuthRoutes.UserSignUp}
        element={
          <Suspense
            fallback={
              <LoaderSpin
                position="absolute"
                size={50}
                color={Colors.blue}
                thickness={0.4}
              />
            }
          >
            <UserSignUp />
          </Suspense>
        }
      />
      <Route
        path={EAuthRoutes.PublisherSignUp}
        element={
          <Suspense
            fallback={
              <LoaderSpin
                position="absolute"
                size={50}
                color={Colors.blue}
                thickness={0.4}
              />
            }
          >
            <PublisherSignUp />
          </Suspense>
        }
      />
      <Route
        path={EAuthRoutes.Confirm}
        element={
          <Suspense
            fallback={
              <LoaderSpin
                position="absolute"
                size={50}
                color={Colors.blue}
                thickness={0.4}
              />
            }
          >
            <Confirm />
          </Suspense>
        }
      />
      <Route
        path={EAuthRoutes.Forgot}
        element={
          <Suspense
            fallback={
              <LoaderSpin
                position="absolute"
                size={50}
                color={Colors.blue}
                thickness={0.4}
              />
            }
          >
            <Forgot />
          </Suspense>
        }
      />
      <Route
        path={EAuthRoutes.Recover}
        element={
          <Suspense
            fallback={
              <LoaderSpin
                position="absolute"
                size={50}
                color={Colors.blue}
                thickness={0.4}
              />
            }
          >
            <Recover />
          </Suspense>
        }
      />
      <Route path={ERoutes.Root} element={<Layout />}>
        <Route
          path={ERoutes.Root}
          element={
            user ? (
              !user.isRoot || checkLengthIsPositive(viewAsPublisherId) ? (
                <Suspense fallback={<LoadPage />}>
                  <PublisherDashboard />
                </Suspense>
              ) : (
                <Suspense fallback={<LoadPage />}>
                  <OwnerDashboard />
                </Suspense>
              )
            ) : (
              <LoadPage />
            )
          }
        />
        <Route
          path={ERoutes.ManagePublishers}
          element={
            <Suspense fallback={<LoadPage />}>
              <PrivateRoute roles={[EUserRole.Owner]}>
                <ManagePublishers />
              </PrivateRoute>
            </Suspense>
          }
        />
        <Route
          path={ERoutes.ManageUsers}
          element={
            <Suspense fallback={<LoadPage />}>
              <PrivateRoute
                roles={[
                  EUserRole.Admin,
                  checkLengthIsPositive(viewAsPublisherId)
                    ? EUserRole.Owner
                    : EUserRole.Admin,
                ]}
              >
                <ManageUsers />
              </PrivateRoute>
            </Suspense>
          }
        />
        <Route
          path={ERoutes.ManagePlayers}
          element={
            <Suspense fallback={<LoadPage />}>
              <ManagePlayers />
            </Suspense>
          }
        />
        <Route
          path={ERoutes.InviteUser}
          element={
            <Suspense fallback={<LoadPage />}>
              <PrivateRoute roles={[EUserRole.Admin]}>
                <InviteUser />
              </PrivateRoute>
            </Suspense>
          }
        />
        <Route
          path={ERoutes.Publisher}
          element={
            <Suspense fallback={<LoadPage />}>
              <PrivateRoute roles={[EUserRole.Owner]}>
                <PublisherDashboard />
              </PrivateRoute>
            </Suspense>
          }
        />
        <Route
          path={ERoutes.InvitePublisher}
          element={
            <Suspense fallback={<LoadPage />}>
              <PrivateRoute roles={[EUserRole.Owner]}>
                <InvitePublisher />
              </PrivateRoute>
            </Suspense>
          }
        />
        <Route
          path={ERoutes.CreatePlayer}
          element={
            <Suspense fallback={<LoadPage />}>
              <PrivateRoute
                roles={[EUserRole.Admin, EUserRole.Editor, EUserRole.Owner]}
              >
                <CreateOrEditPlayer />
              </PrivateRoute>
            </Suspense>
          }
        />
        <Route
          path={ERoutes.ViewSetupAsPub}
          element={
            <Suspense fallback={<LoadPage />}>
              <PrivateRoute
                roles={[
                  EUserRole.Owner,
                  EUserRole.Viewer,
                  EUserRole.Admin,
                  EUserRole.Editor,
                ]}
              >
                <CreateOrEditPlayer />
              </PrivateRoute>
            </Suspense>
          }
        />
        <Route
          path={ERoutes.EditPlayer}
          element={
            <Suspense fallback={<LoadPage />}>
              <PrivateRoute
                roles={[EUserRole.Admin, EUserRole.Editor, EUserRole.Owner]}
              >
                <CreateOrEditPlayer />
              </PrivateRoute>
            </Suspense>
          }
        />

        <Route
          path={ERoutes.PlayerDetails}
          element={
            <Suspense fallback={<LoadPage />}>
              <PlayerDetails />
            </Suspense>
          }
        />

        <Route
          path={ERoutes.Tutorials}
          element={
            <Suspense fallback={<LoadPage />}>
              <Tutorials />
            </Suspense>
          }
        />

      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
