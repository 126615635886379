import { combineReducers } from 'redux';
import { authApi } from './api/authApi';
import { userApi } from './api/userApi';
import { publishersApi } from './api/publishersApi';
import user from './slice/user/slice';
import notifications from './slice/notifications/slice';
import publishers from './slice/publishers/slice';
import players from './slice/players/slice';
import playerSortParams from './slice/players/sortParams/slice';
import users from './slice/users/slice';
import { playersApi } from './api/playersApi';
import { usersApi } from './api/usersApi';

const reducers = combineReducers({
  user,
  publishers,
  players,
  playerSortParams,
  users,
  notifications,
  [authApi.reducerPath]: authApi.reducer,
  [publishersApi.reducerPath]: publishersApi.reducer,
  [playersApi.reducerPath]: playersApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
});

export const apiMiddleware = [
  authApi.middleware,
  publishersApi.middleware,
  playersApi.middleware,
  userApi.middleware,
  usersApi.middleware,
];

export default reducers;
