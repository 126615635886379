import { Colors } from '@/environment';
import { memo } from 'react';
import { LoaderSpin } from '../LoaderSpin';
import { StyledButton } from './Button.styled';

interface IButtonProps {
  text: string;
  onClick?: () => void;
  position?: string;
  borderRadius?: string;
  icon?: JSX.Element;
  width?: string;
  height?: string;
  type?: string;
  loading?: boolean;
  backgroundColor?: string;
  backgroundColorHover?: string;
  textColor?: string;
  ghost?: boolean;
  border?: string;
  disabled?: boolean;
  disabledColor?: string;
}

export const Button: React.FC<IButtonProps> = memo(
  ({
    text,
    onClick,
    icon,
    borderRadius = '100px',
    position = 'flex-start',
    width = '100%',
    height = '36px',
    type = 'button',
    loading = false,
    backgroundColor = `${Colors.blue}`,
    textColor = `${Colors.white}`,
    border = 'none',
    disabled = false,
    disabledColor = `${Colors.grey[100]}`,
    backgroundColorHover,
  }) => {
    return (
      <StyledButton
        borderRadius={borderRadius}
        position={position}
        onClick={onClick}
        width={width}
        height={height}
        type={type}
        backgroundColor={backgroundColor}
        textColor={textColor}
        border={border}
        disabled={disabled}
        disabledColor={disabledColor}
        backgroundColorHover={backgroundColorHover}
      >
        {loading ? (
          <LoaderSpin />
        ) : (
          <>
            {icon && icon}
            <span>{text}</span>
          </>
        )}
      </StyledButton>
    );
  },
);

Button.displayName = 'Button';
