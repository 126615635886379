import styled from 'styled-components';
import { Colors } from '@/environment';

export const StatisticInfoTableWrapper = styled.div`
  padding-top: 30px;
`;

export const TableWrapper = styled.div.attrs(
  (props: {
    minHeight: string;
    isFooterShow: boolean;
    tableWidth: number;
  }) => ({
    ...props,
  }),
)`
  width: 100%;
  box-shadow: 0px 8px 24px 3px rgba(6, 71, 159, 0.16);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  .ant-table-wrapper .ant-table-tbody > tr.ant-table-row:nth-child(odd) > td {
    background-color: white;
  }

  .ant-table-wrapper .ant-table-tbody > tr.ant-table-row:nth-child(2n) > td {
    background-color: ${Colors.sidebarBg};
  }

  & .ant-table-thead {
    .table-action {
      border-bottom: none !important;

      &::before {
        display: none;
      }
    }
  }

  & .ant-table-tbody tr td {
    color: ${Colors.grey[100]};
    border-radius: 0px !important;
  }

  & #popover-btn {
    visibility: hidden;
    cursor: pointer;
  }

  .table-action {
    white-space: nowrap;
    padding: 0px !important;
    height: 52px !important;
    box-shadow: none;
    transition: 0ms all !important;
    justify-content: center;
    align-items: center;

    &::after {
      display: none;
    }
  }

  & #popover-active {
    z-index: 10;
  }

  & .ant-table-tbody .ant-table-row {
    &:hover {
      .table-action {
        display: flex !important;
      }

      #popover-btn {
        visibility: visible;
      }
    }
  }

  & .ant-table-thead .ant-table-column-sort .ant-table-column-sorters {
    color: ${Colors.dark} !important;
  }

  & .ant-table-column-sorter {
    display: none;
  }

  & .ant-table-column-has-sorters {
    background-color: transparent !important;
  }

  & .ant-table-column-has-sorters:hover {
    background-color: transparent !important;
  }

  & .ant-table-thead .ant-table-cell {
    background-color: ${Colors.white};
    color: ${Colors.text.main};
    font-weight: 700;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    line-height: 20px;

    padding-top: 25px;
    padding-bottom: 17px;
    white-space: nowrap;
  }

  & .ant-table-cell-scrollbar {
    display: none !important;
  }

  th::before {
    background-color: transparent !important;
  }

  & .ant-table-tbody .ant-table-cell {
    font-weight: 500;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    line-height: 20px;
    color: ${Colors.text.bold};

    &:not(.table-action) {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: ${Colors.hover.tableItem};
    transition: 0ms all !important;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    transition: 0ms all !important;
  }

  .ant-table-wrapper .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }

  & .ant-table-tbody .ant-table-row {
    transition: 0ms all !important;
  }

  & .ant-table-tbody tr td {
    border-top: 0px !important;

    :first-child {
      padding-left: 20px;
    }
  }

  & .ant-table-body {
    overflow: hidden scroll !important;
    height: 100% !important;
    min-height: ${({ minHeight = '100vh' }) => minHeight} !important;
    scrollbar-width: auto;
    scrollbar-color: rgba(161, 161, 170, 0.1) transparent;

    ::-webkit-scrollbar {
      width: 4px;
      height: 6px;
    }

    ::-webkit-scrollbar-thumb {
      width: 4px;
      background: ${Colors.text.main};
      opacity: 0.2;
      border-radius: 24px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${Colors.scrollbar};
    }

    @media (max-width: 1200px) {
      overflow: auto scroll !important;
    }
  }

  & .ant-table-thead > tr > th {
    border-bottom: none;

    :first-child {
      padding-left: 20px;
    }
  }

  & .ant-spin.ant-spin-spinning {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-popover-placement-leftTop {
    padding-right: 10px !important;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td.table-type {
    padding: 12px 20px 12px 20px;

    & > :first-child {
      min-width: 16px;
      min-height: 16px;
      justify-content: flex-start;
    }
  }

  .ant-table-wrapper .ant-table-thead > tr > th.table-type {
    box-shadow: none;
    border-bottom: none;
    padding: 25px 0px 17px 20px;

    &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }

  .ant-table-wrapper .ant-table-tbody > tr > td.table-name {
    padding-left: 20px;
  }

  .ant-table-wrapper .ant-table-thead > tr > th.table-name {
    padding-left: 20px;
  }

  & .ant-table-summary {
    background: ${Colors.white};
    display: ${(props) => (!props.isFooterShow ? 'none' : 'flex')};
    justify-content: center;
    width: ${(props) => props.tableWidth}px;

    & tr td {
      border-bottom: none;
    }
  }
`;

export const FiltersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px 0px 10px;
`;
