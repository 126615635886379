import { Colors } from '@/environment';
import styled from 'styled-components';

export const Label = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${Colors.grey[100]};
  padding-left: 17px;
  padding-bottom: 5px;
`;
