import { Colors } from '@/environment';
import styled from 'styled-components';

export const SelectLogoPlaceholder = styled.div.attrs(
  (props: { pubActive: boolean }) => ({
    ...props,
  }),
)`
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  background: ${({ pubActive }) =>
    pubActive ? Colors.text.main : Colors.grey[100]};
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  color: ${Colors.white};
`;
