import { useEffect } from 'react';
import { Header, Sidebar } from '@/components/ui';
import { Content, LayoutWrap } from './Layout.styled';
import { useLazyGetMeQuery } from '@/redux/api/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux';
import { updateUserInfo } from '@/redux/slice/user/slice';
import { useLazyGetPublisherQuery } from '@/redux/api/publishersApi';
import {
  clearPublishersInfo,
  setCurrentPublisher,
} from '@/redux/slice/publishers/slice';
import { Outlet, useNavigate } from 'react-router-dom';
import { EAuthRoutes } from '@/routes';
import { BreadcrumbsProvider } from '../BreadcrumbsProvider';
import { useNotifications } from '@/hooks/useNotifications';
import { NotificationText, Icon } from '@/components/common';
import { IconType } from '@/consts';
import { clearPlayersInfo } from '@/redux/slice/players/slice';
import { clearPlayerSortParams } from '@/redux/slice/players/sortParams/slice';
import { clearUsersInfo } from '@/redux/slice/users/slice';

export const Layout = () => {
  const { isAuthorized, notification } = useSelector((store: RootState) => ({
    isAuthorized: store.user.isAuthorized,
    notification: store.notifications,
  }));
  const { contextHolder, openNotification } = useNotifications();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem('accessToken');

  const [getMe, { isLoading }] = useLazyGetMeQuery();
  const [getPublisher] = useLazyGetPublisherQuery();

  const { user } = useSelector((store: RootState) => ({
    user: store.user.data,
  }));

  useEffect(() => {
    if (!isAuthorized && token && !isLoading) {
      getMe()
        .unwrap()
        .then((res) => {
          dispatch(updateUserInfo(res));
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isAuthorized && !token) {
      dispatch(clearPlayersInfo());
      dispatch(clearPlayerSortParams());
      dispatch(clearPublishersInfo());
      dispatch(clearUsersInfo());

      navigate(`${EAuthRoutes.Login}/`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized, navigate, token]);

  useEffect(() => {
    if (user && user.publisherId) {
      getPublisher({ publisherId: user?.publisherId })
        .unwrap()
        .then((res) => dispatch(setCurrentPublisher(res)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, user?.publisherId]);

  useEffect(() => {
    if (notification.message.length > 0) {
      openNotification(
        <NotificationText
          message={notification.message}
          icon={<Icon name={IconType.TYNotificationCheck} />}
        />,
        '#E6FAF5',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification.message]);

  return (
    <>
      {contextHolder}
      <LayoutWrap>
        <BreadcrumbsProvider>
          <Header />
          <Content>
            <Sidebar />
            <Outlet />
          </Content>
        </BreadcrumbsProvider>
      </LayoutWrap>
    </>
  );
};
