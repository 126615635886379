import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IManagePubsRes,
  IPublisher,
  IPublisherStatistic,
  TManagePubsPub,
} from '@/types/publisher-types';

import { baseQueryWithReauth } from './query';

export const publishersApi = createApi({
  reducerPath: 'publishersApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getPublishers: builder.query<
      IPublisher[],
      Partial<{
        limit: number;
        offset: number;
        orderBy: string;
        orderAsc: boolean;
        search: string;
        startTime?: string;
        endTime?: string;
      }>
    >({
      query: ({
        limit = 20,
        offset = 0,
        orderBy = 'playbackCount',
        orderAsc = false,
        search = '',
        startTime,
        endTime,
      }) => ({
        url: `admin/publishers?orderBy=${orderBy}&orderAsc=${orderAsc}&offset=${offset}&limit=${limit}&q=${search}${
          startTime ? `&startTime=${startTime}&endTime=${endTime}` : ''
        }`,
        method: 'GET',
      }),
    }),
    getPublisher: builder.query<
      IPublisher,
      Partial<{ publisherId: string; isOwner: boolean }>
    >({
      query: ({ publisherId, isOwner }) => ({
        url: isOwner
          ? `admin/publishers/${publisherId}`
          : `publisher?publisherId=${publisherId}`,
        method: 'GET',
      }),
    }),
    getOwnerPublishers: builder.query<
      IManagePubsRes,
      Partial<{ limit: number; offset: number; active: boolean }>
    >({
      query: ({ limit = 20, offset = 0, active = true }) => ({
        url: `admin/publishers/list?limit=${limit}&offset=${offset}&active=${active}`,
        method: 'GET',
      }),
    }),
    updateOwnerPub: builder.mutation<
      TManagePubsPub,
      Partial<{
        data: Partial<{ active: boolean }>;
        publisherId: string;
      }>
    >({
      query: ({ data, publisherId }) => ({
        url: `admin/publishers/${publisherId}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    getOwnerStats: builder.query<
      IPublisherStatistic,
      Partial<{ search?: string; startTime?: string; endTime?: string }>
    >({
      query: ({ search = '', startTime, endTime }) => ({
        url: `admin/publishers/stats?q=${search}${
          startTime ? `&startTime=${startTime}&endTime=${endTime}` : ''
        }`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazyGetPublishersQuery,
  useLazyGetPublisherQuery,
  useLazyGetOwnerPublishersQuery,
  useUpdateOwnerPubMutation,
  useLazyGetOwnerStatsQuery,
} = publishersApi;
