import { BaseFormSwitch } from '@/components/common';
import { RootState } from '@/redux';
import { useUpdateOwnerPubMutation } from '@/redux/api/publishersApi';
import { updateManagePubsData } from '@/redux/slice/publishers/slice';
import { Dispatch, memo, SetStateAction, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EPublisherTabs } from '../../../pages/ManagePublishers';
import {
  StatusContainer,
  PublisherSettingsWrapper,
} from './PublisherSettings.styled';

interface UserSettingsProps {
  publisherId: string;
  setCurrentTab: Dispatch<SetStateAction<EPublisherTabs>>;
}

export const PublisherSettings: React.FC<UserSettingsProps> = memo(
  ({ publisherId, setCurrentTab }) => {
    const { publishers } = useSelector((store: RootState) => ({
      publishers: store.publishers.managePubsData.items,
    }));

    const findPub = publishers.find((pub) => pub.id === publisherId);

    const [updateStatus] = useUpdateOwnerPubMutation();

    const dispatch = useDispatch();

    const pubStatus = useMemo(() => {
      if (publisherId && findPub) {
        return findPub.active;
      }
      return false;
    }, [findPub, publisherId]);

    const changeStatus = () => {
      updateStatus({
        data: { active: !pubStatus },
        publisherId: publisherId,
      })
        .unwrap()
        .then((res) => {
          dispatch(updateManagePubsData(res));
          if (res.active) {
            setCurrentTab(EPublisherTabs.Active);
          } else {
            setCurrentTab(EPublisherTabs.Inactive);
          }
        });
    };

    return (
      <PublisherSettingsWrapper>
        <StatusContainer>
          <span className="status">
            Status {findPub?.active ? `(active)` : `(inactive)`}
          </span>
          <BaseFormSwitch
            value={pubStatus}
            name="test"
            onChange={changeStatus}
          />
        </StatusContainer>
      </PublisherSettingsWrapper>
    );
  },
);

PublisherSettings.displayName = 'PublisherSettings';
