/* eslint-disable indent */
import { IconType } from '@/consts';
import { ERoutes } from '@/routes';

export default function useNavigationData(isAdmin: boolean, isRoot: boolean) {
  const TutoriaRoute = {
    title: 'Tutorials',
    icon: IconType.TYTutorialsIcon,
    path: ERoutes.Tutorials,
  };
  return isAdmin
    ? [
        {
          title: 'Dashboard',
          icon: IconType.TYDashboardIcon,
          path: ERoutes.Root,
        },
        ...(isRoot
          ? [
              {
                title: 'Manage publishers',
                icon: IconType.TYManagePublishers,
                path: ERoutes.ManagePublishers,
              },
              {
                title: 'Manage players',
                icon: IconType.TYManageUsersIcon,
                path: ERoutes.ManagePlayers,
              },
            ]
          : [
              {
                title: 'Manage players',
                icon: IconType.TYManagePlayersIcon,
                path: ERoutes.ManagePlayers,
              },
              {
                title: 'Manage users',
                icon: IconType.TYManageUsersIcon,
                path: ERoutes.ManageUsers,
              },
            ]),
        TutoriaRoute,
      ]
    : [
        {
          title: 'Dashboard',
          icon: IconType.TYDashboardIcon,
          path: ERoutes.Root,
        },
        {
          title: 'Manage players',
          icon: IconType.TYManagePlayersIcon,
          path: ERoutes.ManagePlayers,
        },
        TutoriaRoute,
      ];
}
