import { Dayjs } from 'dayjs';
import { Nullable } from './types/generic';
import { IPublisher } from './types/publisher-types';

export type TNavItem = {
  title: string;
  icon: string;
  path: string;
};

export type TOption = {
  value: string;
  title: string;
  tooltipText?: string;
};

export type RangeValue = [Dayjs | null, Dayjs | null] | null;

export type TPlayerInfo = {
  title: string;
  description: string;
  buttonText: string;
  image: string;
  value: string;
};

export interface IPlayerData {
  articlesCount: number | string;
  playbackCount: number | string;
  playbackDuration: number | string;
  color: string;
  createdAt: string;
  download: boolean;
  id: string;
  language: boolean;
  name: string;
  publisher: IPublisher;
  publisherId: string;
  rating: boolean;
  status: Nullable<string>;
  type: string;
  updatedAt: string;
  voice: boolean;
  width: Nullable<number>;
  speed: boolean;
  preview?: string;
}

export interface IDataType {
  key: string;
  name: string;
  playbackCount?: string | number;
  playbackDuration?: string | number;
  avgPlayTime?: string | number;
  avgPlaybackDuration?: string | number;
  createdBy?: string;
  editor?: string;
  updatedAt?: string;
  createdAt?: string;
  articlesCount?: string;
  status?: string;
  advPlayed?: number;
  url?: string;
  type?: string;
  isDropdownShow?: boolean;
  score?: number;
  active?: boolean;
}

export type TPlayerCreateFields = Omit<
  IPlayerData,
  | 'createdAt'
  | 'id'
  | 'publisher'
  | 'publisherId'
  | 'updatedAt'
  | 'articlesCount'
  | 'playbackCount'
  | 'playbackDuration'
>;

export type TPlayerAddlFields = Pick<
  IPlayerData,
  'createdAt' | 'id' | 'publisher' | 'publisherId' | 'updatedAt'
>;

export enum EInvitationStatus {
  Submitted,
  SentEarlier,
  UserAlready,
}

export interface IInvatationStatus {
  status: EInvitationStatus;
  message: string;
  color: string;
}

export interface IFilterOption {
  label: string;
  value: string;
  category: string;
}
